$carousel-height: 650px;

.h-carousel {
  position: relative;
  width: 100%;
  height: calc(100vh - 74px);

  @include mq(sm) {
    height: 100vh;
    max-height: $carousel-height;
  }

  &:hover {
    .slick-active .main,
    .slick-active .left,
    .slick-active .right {
      animation-play-state: paused;
    }
  }

  &__item {
    position: relative;
    width: 100%;
    height: calc(100vh - 74px);
    // background-color: #0c0e0b;
    // background-position: center center;
    // background-size: cover;

    @include mq(sm) {
      height: 100vh;
      max-height: $carousel-height;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: calc(100% - 24px); // 54px é +- a largura dos dots.
    max-width: 784px;
    height: calc(100vh - 74px);
    color: #fff;

    @include mq(sm) {
      height: 100vh;
      max-height: $carousel-height;
    }
  }

  &__description {
    display: block;
    // max-width: 420px;
    max-width: 580px;
    margin-bottom: 1.1538461538461537em;
    transform: translateY(30px);
    transition: transform .5s .5s, opacity .4s .5s;
    font-size: 16px;
    letter-spacing: .1em;
    line-height: 1.2;
    text-shadow: 0px 3px 7px rgba(0, 0, 0, 0.67);
    opacity: 0;

    @include mq(sm) {
      font-size: 18px;
    }

    @include mq(md) {
      font-size: 22px;
    }

    @include mq(lg) {
      font-size: 26px;
    }

    .slick-active & {
      transform: translateY(0);
      opacity: 1;
    }
  }

  &__title {
    display: block;
    transform: translateY(30px);
    transition: transform .5s .6s, opacity .4s .6s;
    font-size: 24px;
    font-weight: bold;
    letter-spacing: 0.04em;
    line-height: 1.12;
    text-shadow: 0px 3px 7px rgba(0, 0, 0, 0.67);
    text-transform: uppercase;
    opacity: 0;

    @include mq(sm) {
      font-size: 32px;
    }

    @include mq(md) {
      font-size: 44px;
    }

    @include mq(lg) {
      font-size: 55px;
    }

    .slick-active & {
      transform: translateY(0);
      opacity: 1;
    }
  }

  &__footer {
    display: block;
    margin-top: 42px;
    transform: translateY(30px);
    transition: transform .5s .7s, opacity .4s .7s;
    opacity: 0;

    @include mq(md) {
      margin-top: 85px;
    }

    .slick-active & {
      transform: translateY(0);
      opacity: 1;
    }
  }

  &__button {
    display: inline-block;
    position: relative;
    // min-width: 262px;
    padding: 16px 25px;
    border: 1px solid #fff;
    color: inherit;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: .05em;
    line-height: 1;
    text-align: center;
    text-transform: uppercase;
    overflow: hidden;
    z-index: 2;

    @include mq(md) {
      min-width: 262px;
      padding: 25px;
      font-size: 20px;
    }

    &:before,
    &:after {
      display: block;
      position: absolute;
      top: 0;
      left: -1px;
      width: 100%;
      height: 0;
      padding-bottom: 100%;
      transform: translate(-100%, -100%);
      border-radius: 50%;
      content: "";
      z-index: -1;
    }

    &:before {
      transition: transform .4s;
      background: $turquoise;
    }

    &:after {
      transition: transform .4s .2s;
      background: $purple;
    }

    &:hover {
      color: #fff;
      text-decoration: none;

      &:before,
      &:after {
        transform: scale(3) translate(0, -33.333%);
        // transform-origin: 100% 100%;
      }
    }
  }

  &__dots {
    position: relative;
    width: 100%;
    max-width: em(1176);
    margin: 0 auto;
    padding-right: rem(15);
    padding-left: rem(15);
  }

  &__dots-list {
    position: absolute;
    top: (-$carousel-height / 2);
    right: 0;
    padding: 2em;
    transform: translateY(-50%);

    @media only screen and (max-height: 650px) {
      top: -50vh;
    }
  }

  &__bg {
    position: absolute;
    top: 0; right: 0; bottom: 0; left: 0;
    background-color: #000;
    background-position: 50% 50%;
    background-size: cover;
    overflow: hidden;
    z-index: -100;
    pointer-events: none;
  }

  &__video {
    position: absolute;
    top: 50%;
    left: 50%;
    width: auto;
    min-width: 100%;
    height: auto;
    min-height: 100%;
    transform: translate(-50%,-50%);
  }

  .slick-dots {
    margin: 0;
    padding: 0;
    list-style: none;
    z-index: 2;

    li {
      margin-top: 20px;
      margin-bottom: 20px;

      @include mq(sm) {
        margin-top: 30px;
        margin-bottom: 30px;
      }

      @include mq(md) {
        margin-top: 42px;
        margin-bottom: 42px;
      }
    }

    $dot-dimensions: 14px;
    $orbit-dimensions: 34px;

    button {
      @include appearance(none);
      display: block;
      position: relative;
      right: 0;
      width: $dot-dimensions;
      height: $dot-dimensions;
      transition: background-color .2s;
      border: 0;
      border-radius: 50%;
      background: #b7b7b7;
      font-size: 0;

      .main {
        display: block;
        position: absolute;
        top: #{($dot-dimensions - $orbit-dimensions) / 2};
        left: #{($dot-dimensions - $orbit-dimensions) / 2};
        width: $orbit-dimensions;
        height: $orbit-dimensions;
        // transform: scale(.1);
        // transition: transform 1s;
        clip: rect(0px, #{$orbit-dimensions}, #{$orbit-dimensions}, #{$orbit-dimensions / 2});
      }

      .left,
      .right {
        display: block;
        position: absolute;
        width: $orbit-dimensions;
        height: $orbit-dimensions;
        transition: border-color .2s;
        border: 1px solid #b7b7b7;
        border-radius: 50%;
        clip: rect(0px, #{$orbit-dimensions / 2}, #{$orbit-dimensions}, 0px);
      }

      .left,
      .right,
      .main {
        display: block;
        animation-fill-mode: forwards;
        animation-iteration-count: 1;
        animation-timing-function: linear;
      }

      .main {
        animation-duration: .01s;
        animation-delay: 4s;
      }

      .left {
        animation-duration: 8s;
      }

      .right {
        animation-duration: 4s;
      }

      &:focus {
        outline: none;
      }
    }

    .slick-active {

      button {
        background: $turquoise;
      }

      .main {
        // transform: scale(1);
        animation-name: close-wrapper;
      }

      .left {
        border-color: $turquoise;
        animation-name: left-spin;
      }

      .right {
        border-color: $turquoise;
        animation-name: right-spin;
      }
    }
  }
}

@keyframes right-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(180deg);
  }
}

@keyframes left-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes close-wrapper {
  to {
    clip: rect(auto, auto, auto, auto);
  }
}
