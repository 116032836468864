.banner {
  background-position: 50% 50%;
  background-size: cover;

  &__content {
    display: flex;
    flex-direction: column;
    // align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 814px;
    height: 120vw;
    max-height: 600px;
    margin: 0;

    @include mq(sm) {
      height: 100vh;
    }
  }

  &__title {
    display: block;
    margin-top: 0;
    margin-bottom: 32px;
    color: #fff;
    font-size: 32px;
    font-weight: bold;
    letter-spacing: .05em;
    line-height: 1;
    // text-align: center;
    text-shadow: 0px 3px 7px rgba(0, 0, 0, 0.67);
    text-transform: uppercase;

    @include mq(sm) {
      font-size: 45px;
    }

    @include mq(md) {
      font-size: 58px;
    }
  }

  &__description {
    display: block;
    color: #fff;
    font-size: 18px;
    letter-spacing: 0.1em;
    line-height: 1.2;
    // text-align: center;
    text-shadow: 0px 3px 7px rgba(0, 0, 0, 0.67);

    @include mq(sm) {
      font-size: 20px;
    }

    @include mq(md) {
      font-size: 23px;
    }

    p {
      margin: 0;

      + p {
        margin-top: 1em;
      }
    }
  }
}
