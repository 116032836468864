.h-jobs {
  background-image: url('../images/bg-cover-home-jobs.jpg');
  background-position: 50% 50%;
  background-size: cover;

  &__container {
    display: block;
    max-width: 813px;
    margin: 0 auto;
    padding-top: 55px;
    padding-bottom: 148px;
    color: #fff;
  }

  &__title {
    display: block;
    margin-top: 0;
    margin-bottom: 0.6323529411764706em;
    font-size: 32px;
    font-weight: 300;
    letter-spacing: .05em;
    line-height: 1.1176470588235294;
    text-align: center;
    text-transform: uppercase;

    @include mq(sm) {
      font-size: 38px;
    }

    @include mq(md) {
      font-size: 45px;
    }

    @include mq(lg) {
      font-size: 55px;
    }

    b {
      color: $turquoise;
      font-weight: 700;
    }
  }

  &__description {
    display: block;
    margin-bottom: 24px;
    font-size: 18px;
    letter-spacing: .05em;
    line-height: 1.24;
    text-align: center;

    @include mq(sm) {
      font-size: 20px;
    }

    @include mq(md) {
      font-size: 24px;
    }
  }

  &__grid {
    display: grid;
    grid-template-columns: repeat(1, minmax(260px, auto) [col-start]);
    grid-column-gap: 16px;
    grid-row-gap: 7px;

    @include mq(only-sm) {
      grid-template-columns: repeat(2, minmax(260px, auto) [col-start]);
    }

    @include mq(md) {
      grid-template-columns: repeat(3, minmax(auto, 260px) [col-start]);
    }
  }

  &__item {

    @include mq(xs) {
      &:nth-child(2n) {
        .h-jobs__button {
          background: rgba($turquoise, .2);
          color: $turquoise;
        }
      }
    }

    @include mq(only-sm) {
      &:nth-child(4n+3), &:nth-child(4n+4) {
        .h-jobs__button {
          background: rgba($turquoise, .2);
          color: $turquoise;
        }
      }
    }

    @include mq(md) {
      &:nth-child(6n+4), &:nth-child(6n+5), &:nth-child(6n+6) {
        .h-jobs__button {
          background: rgba($turquoise, .2);
          color: $turquoise;
        }
      }
    }
  }

  &__button {
    display: flex;
    position: relative;

    align-items: center;
    min-height: 70px;
    padding: 0 15px;
    border: 1px solid #fff;
    background: rgba($purple, .2);
    color: $purple;
    overflow: hidden;
    z-index: 2;

    &:before {
      display: block;
      position: absolute;
      top: 0; right: 0; bottom: 0; left: 0;
      transform: translateX(calc(-100% - 1px));
      transition: transform .5s;
      background: currentColor;
      content: "";
      // opacity: 1;
      z-index: -1;
    }

    &:hover {
      color: $purple;
      text-decoration: none;

      &:before {
        transform: translateX(0);
      }

      .h-jobs__button-icon {
        left: calc(100% - 33px);
        color: #fff;
      }

      .h-jobs__button-text {
        transform: translateX(-63px);
        color: #fff;
      }
    }

    &-icon {
      display: block;
      position: relative;
      left: 0;
      flex: 0 0 33px;
      width: 33px;
      height: 33px;
      margin-right: 30px;
      transition: left .4s;
      color: #fff;
    }

    &-text {
      flex: 0 0 calc(100% - 63px);
      transition: transform .6s;
      color: #fff;
      font-size: 18px;
      font-weight: bold;
      letter-spacing: .05em;
      line-height: 1.2;
      text-transform: uppercase;
    }
  }

  &__footer {
    align-items: center;
    justify-content: space-between;
    margin-top: 23px;
    color: #fff;
    font-size: 16px;
    letter-spacing: .1em;

    @include mq(sm) {
      display: flex;
      font-size: 18px;
      text-align: center;
    }

    @include mq(md) {
      font-size: 20px;
    }

    &-icon {
      display: block;
      width: 30px;
      height: 30px;
      margin-right: 14px;
      transition: color .4s;
      color: $turquoise;
    }

    &-button {
      display: flex;
      flex-basis: 250px;
      justify-content: center;
      padding: 16px 43px;
      transition: color .4s, border-color .4s, background .4s;
      border: 1px solid $turquoise;
      border-radius: 32px;
      color: #fff;
      font-size: 18px;
      font-weight: bold;
      letter-spacing: .05em;
      text-transform: uppercase;
      white-space: nowrap;

      @include mq(xs) {
        margin-top: 1em;
      }

      &:hover {
        border-color: $turquoise;
        background: $turquoise;
        color: #3a3a3a;
        text-decoration: none;

        .h-jobs__footer-icon {
          color: #3a3a3a;
        }
      }
    }
  }
}
