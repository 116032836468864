.og-header {
  &__container {
    // display: flex;
    width: 100%;
    max-width: 970px;
    margin: 0 auto;
    padding-top: 42px;
    padding-bottom: 64px;
    text-align: center;

    @include mq(md) {
      padding-top: 0;
      padding-bottom: 42px;
    }

    // @include mq(xs) {
    //   flex-wrap: wrap;
    //   align-content: center;
    //   padding-bottom: 79px;
    // }
  }

  &__title {
    display: block;
    // flex: 1 1 290px;
    max-width: 100%;
    color: #000;
    font-size: 32px;
    font-weight: 300;
    letter-spacing: .05em;
    line-height: 1.018;
    text-align: center;
    text-transform: uppercase;

    @include mq(only-sm) {
      font-size: 45px;
    }

    @include mq(md) {
      color: #fff;
      font-size: 55px;
    }

    @include mq(xs) {
      margin: 0 0 1em;
    }

    strong {
      font-weight: bold;
    }
  }

  &__description {
    display: block;
    flex: 0 1 650px;
    max-width: 100%;
    color: rgb(148, 148, 148);
    font-size: 18px;
    letter-spacing: .05em;
    line-height: 1.2;

    @include mq(md) {
      font-size: 21px;
    }

    p {
      margin: 0;

      + p {
        margin-top: 1em;
      }
    }
  }
}
