.footer {
  position: relative;
  background: #252323;
  z-index: 2;

  &__container {
    display: flex;
  }

  &__image {
    align-self: flex-end;
    margin-left: -40px;

    img {
      margin-top: -85px;
    }

    @include mq(xs) {
      position: absolute;
      bottom: 0;
      width: calc(33.333% + 40px);

      img {
        width: 100%;
      }
    }
  }

  &__wrapper {
    display: flex;
    flex-grow: 1;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding-top: 15px;

    @include mq(sm) {
      flex-wrap: nowrap;
      padding: 0 56px;
    }
  }

  &__item {
    flex: 1 1 55%;

    @include mq(xs) {
      &:first-child {
        flex-basis: 33.33333%;
      }
    }

    @include mq(sm) {
      flex: 0 1 25%;
    }

    &--large {
      flex-basis: 66.66666%;

      @include mq(sm) {
        flex-basis: 40%;
      }
    }

    &--small {
      flex-basis: 66.66666%;

      @include mq(sm) {
        flex-basis: 20%;
      }
    }
  }

  &__logo {
    display: block;
    width: 88px;
    height: 88px;
    transition: color .3s;
    color: #fff;

    @include mq(sm) {
      width: 114px;
      height: 114px;
    }

    &:hover {
      color: $turquoise;
    }
  }

  &__nav {
    margin: 0;
    padding: 0;
    color: #fff;
    font-size: 14px;
    letter-spacing: .05em;
    list-style: none;

    @include mq(sm) {
      font-size: 16px;
    }

    li + li {
      margin-top: 8px;

      @include mq(sm) {
        margin-top: 18px;
      }
    }

    a {
      color: inherit;

      &:hover {
        color: $turquoise;
        text-decoration: none;
      }
    }
  }

  &__social {

    @include mq(xs) {
      display: flex;
      align-self: flex-end;
      width: 60%;
      margin-top: 2em;
      margin-bottom: 2em;
      margin-left: auto;
      padding: 0;
      // margin-left: 33.3333%;
      // padding-left: 18px;
    }

    a {
      display: block;
      width: 44px;
      height: 44px;
      // transition: color .3s;
      color: #fff;

      &:hover {
        color: $turquoise;
      }
    }

    li + li {
      margin-left: 24px;

      @include mq(sm) {
        margin-top: 12px;
        margin-left: 0;
      }
    }
  }

  &__copyright {
    color: #fff;
    font-size: 12px;
    letter-spacing: .05em;
    line-height: 1.2;

    @include mq(xs) {
      align-self: flex-end;
      width: 60%;
      margin-left: auto;
      padding-bottom: 15px;
    }
  }
}

