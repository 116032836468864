.ab-slidepanel {
  padding-top: 20px;
  padding-bottom: 40px;

  @include mq(md) {
    padding-top: 43px;
    padding-bottom: 120px;
  }

  &__wrapper {
    display: flex;
    margin-right: -15px;
    margin-left: -15px;
  }

  &__container {
    position: relative;
    flex: 0 0 50%;
    max-width: 50%;

    @include mq(md) {
      padding-right: 15px;
      padding-left: 15px;
    }

    &--left {
      padding-top: 120vw;
      padding-top: 120vmin;

      @include mq(only-sm) {
        padding-top: 62.5vw;
        padding-top: 62.5vmin;
      }

      @include mq(md) {
        padding-top: 465px;
      }
    }

    &--right {
      padding-top: 120vw;
      padding-top: 120vmin;

      @include mq(only-sm) {
        padding-top: 62.5vw;
        padding-top: 62.5vmin;
      }

      @include mq(md) {
        padding-top: 0;
      }
    }
  }

  &__slide,
  &__carousel,
  &__panel,
  &__perspective {
    width: 100%;
    max-width: 558px;
    height: 54.659498207885306vw;
    max-height: 610px;
  }

  &__perspective {
    perspective: 218.63799283154123vw;
    @media only screen and (min-width: 1116px) {
      perspective: 2440px;
    }
  }

  &__panel {
    background-position: 50% 50%;
    background-size: cover;
  }

  &__carousel {
    position: relative;
    width: inherit;
    max-width: inherit;
    height: inherit;
    max-height: inherit;
  }

  &__buttons {
    display: flex;
    position: absolute;
    top: 120vw;
    top: 120vmin;
    justify-content: center;
    width: 100%;
    padding: 0 10px;
    transform: translateY(-50%);
    z-index: 2;

    @include mq(only-sm) {
      top: 62.5vw;
      top: 62.5vmin;
      padding: 0 20px;
    }

    @include mq(md) {
      position: relative;
      top: -25%;
      justify-content: space-between;
      width: auto;
      margin: 0 -27.5px;
      padding: 0;
      transform: translateY(-100%);
    }
  }

  &__button {
    @include appearance(none);
    flex-shrink: 0;
    width: 55px;
    height: 55px;
    margin-right: 5px;
    margin-left: 5px;
    border: 0;
    border-radius: 50%;
    background: #39e2b4;
    // text-indent: -9999px;
    overflow: hidden;
  }

  &__content {
    display: flex;
    position: absolute;
    bottom: 100%;
    flex-direction: column;
    justify-content: center;
    width: calc(100vw - 30px);
    height: 120vw;
    height: 120vmin;
    padding: 15px;
    transition: opacity .2s;
    opacity: 0;

    @include mq(only-sm) {
      height: 62.5vw;
      height: 62.5vmin;
    }

    @include mq(md) {
      width: 100%;
      height: 465px;
      padding-right: 0;
      padding-left: 0;
    }

    .ab-carousel--forward .ab-carousel__item--bottom &,
    .ab-carousel--backward .ab-carousel__item--top &,
    .ab-carousel__item--active & {
      opacity: 1;
    }
  }

  &__title {
    display: block;
    margin-bottom: 0.727273em;
    color: rgb(80, 80, 80);
    font-size: calc(2rem + ((1vw - 3.2px) * 2.6136));
    font-weight: bold;
    letter-spacing: .07em;
    line-height: 1.018;
    text-transform: uppercase;

    @include mq(lg) {
      font-size: 55px;
    }
  }

  &__description {
    display: block;
    color: rgb(148, 148, 148);
    font-size: calc(1.5rem + ((1vw - 3.2px) * 0.3409));
    letter-spacing: .07em;
    line-height: 1.2;

    @include mq(lg) {
      font-size: 21px;
    }
  }
}
