$hexagon-width: 204px;
$hexagon-height: $hexagon-width * 1.15461818;
$hexagon-realWidth: $hexagon-height * 0.57735;
$hexagon-margin: $hexagon-height * 0.28869;
$hexagon-bgsize: $hexagon-realWidth + ($hexagon-margin * 2);
$hexChild-dimensions: $hexagon-height * 0.70709;
$hexChild-top: $hexagon-height * 0.146436;
$hexPseudo-position: $hexagon-height * 0.35355345;
$hexSolid-border: $hexagon-height * 0.5045695649600996;
$hexagon-between: 10px;

.hive {
  display: none;
  position: relative;
  width: 100%;

  &.hive--dark {
    background-color: #000;

    @include mq(md) {
      background-color: transparent;
    }
  }

  &.hive--light {
    background-color: #fff;

    @include mq(md) {
      background-color: transparent;
    }
  }

  @include mq(md) {
    display: block;
    width: $hexagon-realWidth + $hexagon-margin * 2;
    margin-top: $hexagon-between;
    margin-bottom: $hexagon-between;
  }

  &__row {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }

  &__col {
    width: 100%;
    @include mq(md) {
      width: auto;
      margin-top: -75px;
      margin-right: (-$hexagon-margin + $hexagon-between) / 2;
      margin-bottom: -50px;
      margin-left: (-$hexagon-margin + $hexagon-between) / 2;
    }
  }

  &--darkviolet {
    color: $violet;

    &:before,
    &:after {
      color: $violet;
    }
  }

  &--darkpurple {
    color: $purple;

    &:before,
    &:after {
      color: $purple;
    }
  }

  &--purple {
    color: #e6c3ff;

    &:before,
    &:after {
      color: #e6c3ff;
    }
  }

  &--lightpurple {
    color: #f8edff;

    &:before,
    &:after {
      color: #f8edff;
    }
  }

  &--darkgreen {
    color: $turquoise;

    &:before,
    &:after {
      color: $turquoise;
    }

    .hive__content {
      color: $purple;
    }
  }

  &--green {
    color: #baf5e5 ;

    &:before,
    &:after {
      color: #baf5e5 ;
    }
  }

  &--lightgreen {
    color: #ebfcf7;

    &:before,
    &:after {
      color: #ebfcf7;
    }
  }

  &--larger {
    font-size: 1.3888888888888888em;
    font-weight: bold;
  }

  &.has--flip {
    display: block;

    @include mq(xs) {
      &.hive--left {

      }

      &.hive--right {

      }

      &.hive--light {
        .hive__solid {
          background-color: #fff;
        }

        .hive__block  {
          background-color: #fff;
        }

        .hive__content {
          color: #000;
        }
      }

      &.hive--dark {
        .hive__solid {
          background-color: #000;
        }

        .hive__block  {
          background-color: #000;
        }

        .hive__content {
          color: #fff;
        }
      }
    }

    @include mq(only-sm) {
      &.hive--left {

      }

      &.hive--right {

      }

      &.hive--light {
        .hive__solid {
          background-color: #fff;
        }

        .hive__block  {
          background-color: #fff;
        }

        .hive__content {
          color: #000;
        }
      }

      &.hive--dark {
        .hive__solid {
          background-color: #000;
        }

        .hive__block  {
          background-color: #000;
        }

        .hive__content {
          color: #fff;
        }
      }
    }
  }

  @include mq(md) {
    &.has--flip.is--flipped,
    &.has--flip:hover {
      .hive__wrapper {
        transform: rotateY(-180deg);
      }
    }
  }
}

.hive__wrapper {
  margin-right: -15px;
  margin-left: -15px;
  transform-style: preserve-3d;
  transition: .6s;

  @include mq(xs) {
    display: flex;
    min-height: 54.375vw;
  }

  @include mq(only-sm) {
    display: flex;
    min-height: 320px;
  }

  @include mq(md) {
    margin-right: 0;
    margin-left: 0;
  }
}

.hive__container {
  position: relative;
  width: 100%;
  cursor: crosshair;
  overflow: hidden;
  z-index: 2;
  backface-visibility: hidden;

  @include mq(md) {
    width: auto;
  }


  &.hive--scale {
    &-1 {
      transform: scale(0.712727);

      // &.hive--top {
      //   margin-bottom: calc(-28.7273% + #{$hexagon-between * 3});
      // }

      // &.hive--bottom {
      //   margin-top: calc(-28.7273% + #{$hexagon-between * 3});
      // }
    }

    &-2 {
      transform: scale(0.6);

      // &.hive--top {
      //   margin-bottom: calc(-40% + #{$hexagon-between * 3});
      // }

      // &.hive--bottom {
      //   margin-top: calc(-40% + #{$hexagon-between * 3});
      // }
    }

    &-3 {
      transform: scale(0.5629454545454545);

      // &.hive--top {
      //   margin-bottom: calc(-43.70545454545455% + #{$hexagon-between * 3});
      // }

      // &.hive--bottom {
      //   margin-top: calc(-43.70545454545455% + #{$hexagon-between * 3});
      // }
    }

    &-4 {
      transform: scale(0.4160363636363636);

      // &.hive--top {
      //   margin-bottom: calc(-58.39636363636364% + #{$hexagon-between * 3});
      // }

      // &.hive--bottom {
      //   margin-top: calc(-58.39636363636364% + #{$hexagon-between * 3});
      // }
    }
  }

  &.hive--top {
    margin-top: $hexagon-between * 3;
    transform-origin: 50% 0;
  }

  &.hive--bottom {
    margin-bottom: $hexagon-between * 3;
    transform-origin: 50% 100%;
  }
}

.hive__back {
  @include mq(xs) {
    .hive__solid {
      display: flex;
      align-items: center;
      height: 100%;
    }
  }

  @include mq(only-sm) {
    .hive__solid {
      display: flex;
      align-items: center;
      height: 100%;
    }
  }

  @include mq(md) {
    position: absolute;
    top: 0; left: 0;
    transform: rotateY(-180deg);
  }
}

.hive__front {
  flex: 1 0 45%;

  @include mq(xs) {
    .hive__block {
      height: 100%;
      background-repeat: no-repeat;
      background-size: cover;
    }

    .hive--left & {
      order: -1;

      .hive__block {
        background-position: right center;
      }
    }

    .hive--right & {
      order: 2;

      .hive__block {
        background-position: left center;
      }
    }

    .hive--mirror & {
      .hive__block {
        transform: scaleX(-1);
      }
    }

  }

  @include mq(only-sm) {
    .hive__block {
      height: 100%;
      background-repeat: no-repeat;
      background-size: cover;
    }

    .hive--left & {
      order: -1;

      .hive__block {
        background-position: right center;
      }
    }

    .hive--right & {
      order: 2;

      .hive__block {
        background-position: left center;
      }
    }

    .hive--mirror & {
      .hive__block {
        transform: scaleX(-1);
      }
    }

  }
}


.hive__solid,
.hive__block {
  position: relative;

  @include mq(md) {
    width: $hexagon-realWidth;
    height: $hexagon-height;
    margin: 0 $hexagon-margin;
  }

  &:after {
    position: absolute;
    content: "";
  }
}

.hive__solid {
  background: currentColor;

  &:before,
  &:after {
    @include mq(md) {
      border-top: $hexSolid-border solid transparent;
      border-bottom: $hexSolid-border solid transparent;
    }
  }

  &:before {
    @include mq(md) {
      position: absolute;
      left: 100%;
      width: 0;
      border-left: $hexagon-margin solid currentColor;
      content: "";
    }
  }

  &:after {
    @include mq(md) {
      right: 100%;
      width: 0;
      border-right: $hexagon-margin solid currentColor;
    }
  }
}

.hive__block {
  background-color: currentColor;
  background-position: center;
  background-size: auto $hexagon-bgsize;

  &:after {
    @include mq(md) {
      top: 0;
      left: 0;
      width: $hexagon-realWidth;
      height: $hexagon-height;
      background: inherit;
      z-index: 2;
    }

  }
}

.hive__block--top,
.hive__block--bottom {
  @include mq(md) {
    position: absolute;
    top: $hexChild-top;
    width: $hexChild-dimensions;
    height: $hexChild-dimensions;
    transform: scaleX(0.5774) rotate(-45deg);
    background: inherit;
    overflow: hidden;
    z-index: 1;

    &:after {
      position: absolute;
      width: $hexagon-realWidth;
      height: $hexagon-height;
      transform: rotate(45deg) scaleX(1.7321) translateY(-50%);
      transform-origin: 0 0;
      background: inherit;
      content: "";
    }
  }
}

.hive__block--top {
  @include mq(md) {
    left: -$hexPseudo-position;

    &:after {
      background-position: center left;
    }
  }

}

.hive__block--bottom {
  @include mq(md) {
    right: -$hexPseudo-position;

    &:after {
      background-position: center right;
    }
  }

}

.hive__content {
  display: flex;
  align-items: center;
  padding: 1em;
  color: #fff;
  font-size: 18px;
  letter-spacing: .05em;
  line-height: 1.3;
  text-transform: uppercase;

  @include mq(xs) {
    padding: .5em;
    font-size: 15px;
  }

  @include mq(md) {
    position: absolute;
    top: 0;
    left: -30px;
    width: calc(100% + 60px);
    height: 100%;
    padding: 0;
    text-align: center;
    z-index: 2;
  }

  p {
    margin: 0;
  }
}
