.h-hive {
  &__header {
    margin: 50px 0 20px;
    text-align: center;
  }

  &__title {
    display: block;
    max-width: 800px;
    margin-top: 0;
    margin-right: auto;
    margin-bottom: 24px;
    margin-left: auto;
    font-size: 28px;
    letter-spacing: .05em;
    line-height: 1;
    text-align: center;
    text-transform: uppercase;

    @include mq(sm) {
      font-size: 35px;
    }

    @include mq(md) {
      font-size: 44px;
    }

    @include mq(lg) {
      font-size: 55px;
    }

    &--purple {
      color: $purple;
    }
  }

  &__description {
    display: block;
    color: rgb(80, 80, 80);
    font-size: 16px;
    line-height: 1.2;

    @include mq(sm) {
      font-size: 18px;
    }

    @include mq(md) {
      font-size: 22px;
    }

    @include mq(lg) {
      font-size: 26px;
    }
  }
}
