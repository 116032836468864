html,
body {
  font-size: 10px;
}

body {
  font-family: Dosis, sans-serif;
}

body.is-nav--open {
  overflow: hidden;
}

.container-fluid {
  padding-right: 0;
  padding-left: 0;
}

.wrapper {
  width: 100%;
  max-width: 117.6em;
  margin: 0 auto;
  padding-right: 1.5rem;
  padding-left: 1.5rem;
}

.wrapper--small {
  max-width: 102.4em;
}

.la-main {
  padding-top: 74px;
}

@media (min-width: 768px) {
  .la-main {
    padding-top: 126px;
  }
}

.text > * {
  margin: 0;
}

.text > * + * {
  margin-top: 1em;
}

.hive {
  display: none;
  position: relative;
  width: 100%;
}

.hive.hive--dark {
  background-color: #000;
}

@media (min-width: 992px) {
  .hive.hive--dark {
    background-color: transparent;
  }
}

.hive.hive--light {
  background-color: #fff;
}

@media (min-width: 992px) {
  .hive.hive--light {
    background-color: transparent;
  }
}

@media (min-width: 992px) {
  .hive {
    display: block;
    width: 271.987539202px;
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

.hive__row {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.hive__col {
  width: 100%;
}

@media (min-width: 992px) {
  .hive__col {
    width: auto;
    margin-top: -75px;
    margin-right: -28.9993256832px;
    margin-bottom: -50px;
    margin-left: -28.9993256832px;
  }
}

.hive--darkviolet {
  color: #b853ff;
}

.hive--darkviolet:before, .hive--darkviolet:after {
  color: #b853ff;
}

.hive--darkpurple {
  color: #360c6e;
}

.hive--darkpurple:before, .hive--darkpurple:after {
  color: #360c6e;
}

.hive--purple {
  color: #e6c3ff;
}

.hive--purple:before, .hive--purple:after {
  color: #e6c3ff;
}

.hive--lightpurple {
  color: #f8edff;
}

.hive--lightpurple:before, .hive--lightpurple:after {
  color: #f8edff;
}

.hive--darkgreen {
  color: #39e2b4;
}

.hive--darkgreen:before, .hive--darkgreen:after {
  color: #39e2b4;
}

.hive--darkgreen .hive__content {
  color: #360c6e;
}

.hive--green {
  color: #baf5e5;
}

.hive--green:before, .hive--green:after {
  color: #baf5e5;
}

.hive--lightgreen {
  color: #ebfcf7;
}

.hive--lightgreen:before, .hive--lightgreen:after {
  color: #ebfcf7;
}

.hive--larger {
  font-size: 1.3888888888888888em;
  font-weight: bold;
}

.hive.has--flip {
  display: block;
}

@media (max-width: 767px) {
  .hive.has--flip.hive--light .hive__solid {
    background-color: #fff;
  }
  .hive.has--flip.hive--light .hive__block {
    background-color: #fff;
  }
  .hive.has--flip.hive--light .hive__content {
    color: #000;
  }
  .hive.has--flip.hive--dark .hive__solid {
    background-color: #000;
  }
  .hive.has--flip.hive--dark .hive__block {
    background-color: #000;
  }
  .hive.has--flip.hive--dark .hive__content {
    color: #fff;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .hive.has--flip.hive--light .hive__solid {
    background-color: #fff;
  }
  .hive.has--flip.hive--light .hive__block {
    background-color: #fff;
  }
  .hive.has--flip.hive--light .hive__content {
    color: #000;
  }
  .hive.has--flip.hive--dark .hive__solid {
    background-color: #000;
  }
  .hive.has--flip.hive--dark .hive__block {
    background-color: #000;
  }
  .hive.has--flip.hive--dark .hive__content {
    color: #fff;
  }
}

@media (min-width: 992px) {
  .hive.has--flip.is--flipped .hive__wrapper, .hive.has--flip:hover .hive__wrapper {
    transform: rotateY(-180deg);
  }
}

.hive__wrapper {
  margin-right: -15px;
  margin-left: -15px;
  transform-style: preserve-3d;
  transition: .6s;
}

@media (max-width: 767px) {
  .hive__wrapper {
    display: flex;
    min-height: 54.375vw;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .hive__wrapper {
    display: flex;
    min-height: 320px;
  }
}

@media (min-width: 992px) {
  .hive__wrapper {
    margin-right: 0;
    margin-left: 0;
  }
}

.hive__container {
  position: relative;
  width: 100%;
  cursor: crosshair;
  overflow: hidden;
  z-index: 2;
  backface-visibility: hidden;
}

@media (min-width: 992px) {
  .hive__container {
    width: auto;
  }
}

.hive__container.hive--scale-1 {
  transform: scale(0.712727);
}

.hive__container.hive--scale-2 {
  transform: scale(0.6);
}

.hive__container.hive--scale-3 {
  transform: scale(0.5629454545);
}

.hive__container.hive--scale-4 {
  transform: scale(0.4160363636);
}

.hive__container.hive--top {
  margin-top: 30px;
  transform-origin: 50% 0;
}

.hive__container.hive--bottom {
  margin-bottom: 30px;
  transform-origin: 50% 100%;
}

@media (max-width: 767px) {
  .hive__back .hive__solid {
    display: flex;
    align-items: center;
    height: 100%;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .hive__back .hive__solid {
    display: flex;
    align-items: center;
    height: 100%;
  }
}

@media (min-width: 992px) {
  .hive__back {
    position: absolute;
    top: 0;
    left: 0;
    transform: rotateY(-180deg);
  }
}

.hive__front {
  flex: 1 0 45%;
}

@media (max-width: 767px) {
  .hive__front .hive__block {
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .hive--left .hive__front {
    order: -1;
  }
  .hive--left .hive__front .hive__block {
    background-position: right center;
  }
  .hive--right .hive__front {
    order: 2;
  }
  .hive--right .hive__front .hive__block {
    background-position: left center;
  }
  .hive--mirror .hive__front .hive__block {
    transform: scaleX(-1);
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .hive__front .hive__block {
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .hive--left .hive__front {
    order: -1;
  }
  .hive--left .hive__front .hive__block {
    background-position: right center;
  }
  .hive--right .hive__front {
    order: 2;
  }
  .hive--right .hive__front .hive__block {
    background-position: left center;
  }
  .hive--mirror .hive__front .hive__block {
    transform: scaleX(-1);
  }
}

.hive__solid,
.hive__block {
  position: relative;
}

@media (min-width: 992px) {
  .hive__solid,
  .hive__block {
    width: 135.990236469px;
    height: 235.54210872px;
    margin: 0 67.9986513664px;
  }
}

.hive__solid:after,
.hive__block:after {
  position: absolute;
  content: "";
}

.hive__solid {
  background: currentColor;
}

@media (min-width: 992px) {
  .hive__solid:before, .hive__solid:after {
    border-top: 118.847379327px solid transparent;
    border-bottom: 118.847379327px solid transparent;
  }
}

@media (min-width: 992px) {
  .hive__solid:before {
    position: absolute;
    left: 100%;
    width: 0;
    border-left: 67.9986513664px solid currentColor;
    content: "";
  }
}

@media (min-width: 992px) {
  .hive__solid:after {
    right: 100%;
    width: 0;
    border-right: 67.9986513664px solid currentColor;
  }
}

.hive__block {
  background-color: currentColor;
  background-position: center;
  background-size: auto 271.987539202px;
}

@media (min-width: 992px) {
  .hive__block:after {
    top: 0;
    left: 0;
    width: 135.990236469px;
    height: 235.54210872px;
    background: inherit;
    z-index: 2;
  }
}

@media (min-width: 992px) {
  .hive__block--top,
  .hive__block--bottom {
    position: absolute;
    top: 34.4918442325px;
    width: 166.549469655px;
    height: 166.549469655px;
    transform: scaleX(0.5774) rotate(-45deg);
    background: inherit;
    overflow: hidden;
    z-index: 1;
  }
  .hive__block--top:after,
  .hive__block--bottom:after {
    position: absolute;
    width: 135.990236469px;
    height: 235.54210872px;
    transform: rotate(45deg) scaleX(1.7321) translateY(-50%);
    transform-origin: 0 0;
    background: inherit;
    content: "";
  }
}

@media (min-width: 992px) {
  .hive__block--top {
    left: -83.2767251582px;
  }
  .hive__block--top:after {
    background-position: center left;
  }
}

@media (min-width: 992px) {
  .hive__block--bottom {
    right: -83.2767251582px;
  }
  .hive__block--bottom:after {
    background-position: center right;
  }
}

.hive__content {
  display: flex;
  align-items: center;
  padding: 1em;
  color: #fff;
  font-size: 18px;
  letter-spacing: .05em;
  line-height: 1.3;
  text-transform: uppercase;
}

@media (max-width: 767px) {
  .hive__content {
    padding: .5em;
    font-size: 15px;
  }
}

@media (min-width: 992px) {
  .hive__content {
    position: absolute;
    top: 0;
    left: -30px;
    width: calc(100% + 60px);
    height: 100%;
    padding: 0;
    text-align: center;
    z-index: 2;
  }
}

.hive__content p {
  margin: 0;
}

.header {
  position: fixed;
  top: 0;
  width: 100%;
  background: #000;
  z-index: 1050;
}

@media (min-width: 768px) {
  .header {
    padding-top: 30px;
    padding-bottom: 30px;
  }
}

.header__mobile-nav {
  display: flex;
  position: absolute;
  top: 100%;
  right: 0;
  left: 0;
  flex-direction: column;
  align-items: center;
  height: calc(100vh - 74px);
  padding-top: 0;
  padding-bottom: 30px;
  transform: translateY(-100%);
  transition: opacity .3s .2s, transform .5s;
  background: #000;
  opacity: 0;
  z-index: -1;
}

.is-nav--open .header__mobile-nav {
  transform: translateY(0);
  opacity: 1;
}

@media (min-width: 768px) {
  .header__mobile-nav {
    position: static;
    flex-direction: row;
    flex-grow: 1;
    height: auto;
    padding: 0;
    transform: none;
    opacity: 1;
    z-index: 1;
  }
}

.header__logo {
  display: block;
  width: 44px;
  height: 44px;
  transition: color .3s;
  color: #fff;
}

@media (min-width: 768px) {
  .header__logo {
    width: 66px;
    height: 66px;
  }
}

.header__logo:hover {
  color: #39e2b4;
}

.header__container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 15px;
  padding-bottom: 15px;
  background: #000;
}

@media (min-width: 768px) {
  .header__container {
    justify-content: flex-start;
    padding: 0;
  }
}

.header__nav {
  display: flex;
  flex-grow: 1;
  justify-content: center;
}

.header__lang {
  display: flex;
  margin: 0;
  padding: 0;
  color: #fff;
  font-size: 14px;
  letter-spacing: .05em;
  line-height: 1.2;
  list-style: none;
}

@media (max-width: 767px) {
  .header__lang {
    transform: translateY(30px);
    transition: transform .3s .8s, opacity .2s .8s;
    opacity: 0;
  }
  .is-nav--open .header__lang {
    transform: translateY(0);
    opacity: 1;
  }
}

.header__lang-item.is--active {
  color: #39e2b4;
}

.header__lang-item a {
  color: inherit;
}

.header__lang-item a:hover {
  color: inherit;
}

.header__lang-item + .header__lang-item:before {
  display: inline-block;
  padding: 0 1ch;
  content: "|";
}

.header__toggle {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  display: block;
  width: 44px;
  height: 44px;
  padding: .75em .75em;
  border: 0;
  background: transparent;
}

@media (min-width: 768px) {
  .header__toggle {
    display: none;
  }
}

.is-nav--open .header__toggle i {
  transform: rotate(-45deg);
  transform-origin: 50% 50%;
}

.is-nav--open .header__toggle i:first-child {
  transform: rotate(45deg) translate(3px, -3.75px);
  transform-origin: 0 0;
}

.is-nav--open .header__toggle i:last-child {
  transform: rotate(45deg);
  transform-origin: 100% 100%;
}

.header__toggle-icon {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-around;
  height: 100%;
  text-align: left;
}

.header__toggle-icon i {
  display: block;
  width: 100%;
  height: 3px;
  transition: transform 0.3s cubic-bezier(0.25, 0.1, 0.5, 1.5);
  background: #fff;
}

.header__toggle-icon i:first-child {
  width: 57.142857142857146%;
}

.header__toggle-icon i:last-child {
  width: 78.57142857142857%;
}

.nav__list {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0;
  padding: 0;
  list-style: none;
}

@media (min-width: 768px) {
  .nav__list {
    flex-direction: row;
  }
}

.nav__item {
  padding-right: 25px;
  padding-left: 25px;
  color: #fff;
  font-size: 20px;
  line-height: 1.2;
  text-transform: uppercase;
}

.nav__item.is--active {
  color: #39e2b4;
}

@media (max-width: 767px) {
  .nav__item {
    transform: translateX(-30px);
    transition: transform .3s .6s, opacity .2s .6s;
    opacity: 0;
  }
  .nav__item:first-child {
    transition: transform .3s .5s, opacity .2s .5s;
  }
  .nav__item:last-child {
    transition: transform .3s .7s, opacity .2s .7s;
  }
  .is-nav--open .nav__item {
    transform: translateX(0);
    opacity: 1;
  }
}

.nav__button {
  display: block;
  position: relative;
  padding: 10px 0;
  transition: color .2s;
  color: inherit;
}

.nav__button:after {
  display: block;
  position: absolute;
  top: 100%;
  width: 100%;
  height: 8px;
  transform: scaleX(0.01);
  transition: transform .3s, opacity .01s;
  border-radius: 4px;
  background: currentColor;
  content: "";
  opacity: 0;
}

.nav__button:hover {
  color: #39e2b4;
  text-decoration: none;
}

.nav__button:hover:after {
  transform: scaleX(1);
  opacity: 1;
}

.footer {
  position: relative;
  background: #252323;
  z-index: 2;
}

.footer__container {
  display: flex;
}

.footer__image {
  align-self: flex-end;
  margin-left: -40px;
}

.footer__image img {
  margin-top: -85px;
}

@media (max-width: 767px) {
  .footer__image {
    position: absolute;
    bottom: 0;
    width: calc(33.333% + 40px);
  }
  .footer__image img {
    width: 100%;
  }
}

.footer__wrapper {
  display: flex;
  flex-grow: 1;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding-top: 15px;
}

@media (min-width: 768px) {
  .footer__wrapper {
    flex-wrap: nowrap;
    padding: 0 56px;
  }
}

.footer__item {
  flex: 1 1 55%;
}

@media (max-width: 767px) {
  .footer__item:first-child {
    flex-basis: 33.33333%;
  }
}

@media (min-width: 768px) {
  .footer__item {
    flex: 0 1 25%;
  }
}

.footer__item--large {
  flex-basis: 66.66666%;
}

@media (min-width: 768px) {
  .footer__item--large {
    flex-basis: 40%;
  }
}

.footer__item--small {
  flex-basis: 66.66666%;
}

@media (min-width: 768px) {
  .footer__item--small {
    flex-basis: 20%;
  }
}

.footer__logo {
  display: block;
  width: 88px;
  height: 88px;
  transition: color .3s;
  color: #fff;
}

@media (min-width: 768px) {
  .footer__logo {
    width: 114px;
    height: 114px;
  }
}

.footer__logo:hover {
  color: #39e2b4;
}

.footer__nav {
  margin: 0;
  padding: 0;
  color: #fff;
  font-size: 14px;
  letter-spacing: .05em;
  list-style: none;
}

@media (min-width: 768px) {
  .footer__nav {
    font-size: 16px;
  }
}

.footer__nav li + li {
  margin-top: 8px;
}

@media (min-width: 768px) {
  .footer__nav li + li {
    margin-top: 18px;
  }
}

.footer__nav a {
  color: inherit;
}

.footer__nav a:hover {
  color: #39e2b4;
  text-decoration: none;
}

@media (max-width: 767px) {
  .footer__social {
    display: flex;
    align-self: flex-end;
    width: 60%;
    margin-top: 2em;
    margin-bottom: 2em;
    margin-left: auto;
    padding: 0;
  }
}

.footer__social a {
  display: block;
  width: 44px;
  height: 44px;
  color: #fff;
}

.footer__social a:hover {
  color: #39e2b4;
}

.footer__social li + li {
  margin-left: 24px;
}

@media (min-width: 768px) {
  .footer__social li + li {
    margin-top: 12px;
    margin-left: 0;
  }
}

.footer__copyright {
  color: #fff;
  font-size: 12px;
  letter-spacing: .05em;
  line-height: 1.2;
}

@media (max-width: 767px) {
  .footer__copyright {
    align-self: flex-end;
    width: 60%;
    margin-left: auto;
    padding-bottom: 15px;
  }
}

.banner {
  background-position: 50% 50%;
  background-size: cover;
}

.banner__content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  max-width: 814px;
  height: 120vw;
  max-height: 600px;
  margin: 0;
}

@media (min-width: 768px) {
  .banner__content {
    height: 100vh;
  }
}

.banner__title {
  display: block;
  margin-top: 0;
  margin-bottom: 32px;
  color: #fff;
  font-size: 32px;
  font-weight: bold;
  letter-spacing: .05em;
  line-height: 1;
  text-shadow: 0px 3px 7px rgba(0, 0, 0, 0.67);
  text-transform: uppercase;
}

@media (min-width: 768px) {
  .banner__title {
    font-size: 45px;
  }
}

@media (min-width: 992px) {
  .banner__title {
    font-size: 58px;
  }
}

.banner__description {
  display: block;
  color: #fff;
  font-size: 18px;
  letter-spacing: 0.1em;
  line-height: 1.2;
  text-shadow: 0px 3px 7px rgba(0, 0, 0, 0.67);
}

@media (min-width: 768px) {
  .banner__description {
    font-size: 20px;
  }
}

@media (min-width: 992px) {
  .banner__description {
    font-size: 23px;
  }
}

.banner__description p {
  margin: 0;
}

.banner__description p + p {
  margin-top: 1em;
}

.h-carousel {
  position: relative;
  width: 100%;
  height: calc(100vh - 74px);
}

@media (min-width: 768px) {
  .h-carousel {
    height: 100vh;
    max-height: 650px;
  }
}

.h-carousel:hover .slick-active .main,
.h-carousel:hover .slick-active .left,
.h-carousel:hover .slick-active .right {
  animation-play-state: paused;
}

.h-carousel__item {
  position: relative;
  width: 100%;
  height: calc(100vh - 74px);
}

@media (min-width: 768px) {
  .h-carousel__item {
    height: 100vh;
    max-height: 650px;
  }
}

.h-carousel__content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: calc(100% - 24px);
  max-width: 784px;
  height: calc(100vh - 74px);
  color: #fff;
}

@media (min-width: 768px) {
  .h-carousel__content {
    height: 100vh;
    max-height: 650px;
  }
}

.h-carousel__description {
  display: block;
  max-width: 580px;
  margin-bottom: 1.1538461538461537em;
  transform: translateY(30px);
  transition: transform .5s .5s, opacity .4s .5s;
  font-size: 16px;
  letter-spacing: .1em;
  line-height: 1.2;
  text-shadow: 0px 3px 7px rgba(0, 0, 0, 0.67);
  opacity: 0;
}

@media (min-width: 768px) {
  .h-carousel__description {
    font-size: 18px;
  }
}

@media (min-width: 992px) {
  .h-carousel__description {
    font-size: 22px;
  }
}

@media (min-width: 1200px) {
  .h-carousel__description {
    font-size: 26px;
  }
}

.slick-active .h-carousel__description {
  transform: translateY(0);
  opacity: 1;
}

.h-carousel__title {
  display: block;
  transform: translateY(30px);
  transition: transform .5s .6s, opacity .4s .6s;
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 0.04em;
  line-height: 1.12;
  text-shadow: 0px 3px 7px rgba(0, 0, 0, 0.67);
  text-transform: uppercase;
  opacity: 0;
}

@media (min-width: 768px) {
  .h-carousel__title {
    font-size: 32px;
  }
}

@media (min-width: 992px) {
  .h-carousel__title {
    font-size: 44px;
  }
}

@media (min-width: 1200px) {
  .h-carousel__title {
    font-size: 55px;
  }
}

.slick-active .h-carousel__title {
  transform: translateY(0);
  opacity: 1;
}

.h-carousel__footer {
  display: block;
  margin-top: 42px;
  transform: translateY(30px);
  transition: transform .5s .7s, opacity .4s .7s;
  opacity: 0;
}

@media (min-width: 992px) {
  .h-carousel__footer {
    margin-top: 85px;
  }
}

.slick-active .h-carousel__footer {
  transform: translateY(0);
  opacity: 1;
}

.h-carousel__button {
  display: inline-block;
  position: relative;
  padding: 16px 25px;
  border: 1px solid #fff;
  color: inherit;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: .05em;
  line-height: 1;
  text-align: center;
  text-transform: uppercase;
  overflow: hidden;
  z-index: 2;
}

@media (min-width: 992px) {
  .h-carousel__button {
    min-width: 262px;
    padding: 25px;
    font-size: 20px;
  }
}

.h-carousel__button:before, .h-carousel__button:after {
  display: block;
  position: absolute;
  top: 0;
  left: -1px;
  width: 100%;
  height: 0;
  padding-bottom: 100%;
  transform: translate(-100%, -100%);
  border-radius: 50%;
  content: "";
  z-index: -1;
}

.h-carousel__button:before {
  transition: transform .4s;
  background: #39e2b4;
}

.h-carousel__button:after {
  transition: transform .4s .2s;
  background: #360c6e;
}

.h-carousel__button:hover {
  color: #fff;
  text-decoration: none;
}

.h-carousel__button:hover:before, .h-carousel__button:hover:after {
  transform: scale(3) translate(0, -33.333%);
}

.h-carousel__dots {
  position: relative;
  width: 100%;
  max-width: 117.6em;
  margin: 0 auto;
  padding-right: 1.5rem;
  padding-left: 1.5rem;
}

.h-carousel__dots-list {
  position: absolute;
  top: -325px;
  right: 0;
  padding: 2em;
  transform: translateY(-50%);
}

@media only screen and (max-height: 650px) {
  .h-carousel__dots-list {
    top: -50vh;
  }
}

.h-carousel__bg {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #000;
  background-position: 50% 50%;
  background-size: cover;
  overflow: hidden;
  z-index: -100;
  pointer-events: none;
}

.h-carousel__video {
  position: absolute;
  top: 50%;
  left: 50%;
  width: auto;
  min-width: 100%;
  height: auto;
  min-height: 100%;
  transform: translate(-50%, -50%);
}

.h-carousel .slick-dots {
  margin: 0;
  padding: 0;
  list-style: none;
  z-index: 2;
}

.h-carousel .slick-dots li {
  margin-top: 20px;
  margin-bottom: 20px;
}

@media (min-width: 768px) {
  .h-carousel .slick-dots li {
    margin-top: 30px;
    margin-bottom: 30px;
  }
}

@media (min-width: 992px) {
  .h-carousel .slick-dots li {
    margin-top: 42px;
    margin-bottom: 42px;
  }
}

.h-carousel .slick-dots button {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  display: block;
  position: relative;
  right: 0;
  width: 14px;
  height: 14px;
  transition: background-color .2s;
  border: 0;
  border-radius: 50%;
  background: #b7b7b7;
  font-size: 0;
}

.h-carousel .slick-dots button .main {
  display: block;
  position: absolute;
  top: -10px;
  left: -10px;
  width: 34px;
  height: 34px;
  clip: rect(0px, 34px, 34px, 17px);
}

.h-carousel .slick-dots button .left,
.h-carousel .slick-dots button .right {
  display: block;
  position: absolute;
  width: 34px;
  height: 34px;
  transition: border-color .2s;
  border: 1px solid #b7b7b7;
  border-radius: 50%;
  clip: rect(0px, 17px, 34px, 0px);
}

.h-carousel .slick-dots button .left,
.h-carousel .slick-dots button .right,
.h-carousel .slick-dots button .main {
  display: block;
  animation-fill-mode: forwards;
  animation-iteration-count: 1;
  animation-timing-function: linear;
}

.h-carousel .slick-dots button .main {
  animation-duration: .01s;
  animation-delay: 4s;
}

.h-carousel .slick-dots button .left {
  animation-duration: 8s;
}

.h-carousel .slick-dots button .right {
  animation-duration: 4s;
}

.h-carousel .slick-dots button:focus {
  outline: none;
}

.h-carousel .slick-dots .slick-active button {
  background: #39e2b4;
}

.h-carousel .slick-dots .slick-active .main {
  animation-name: close-wrapper;
}

.h-carousel .slick-dots .slick-active .left {
  border-color: #39e2b4;
  animation-name: left-spin;
}

.h-carousel .slick-dots .slick-active .right {
  border-color: #39e2b4;
  animation-name: right-spin;
}

@keyframes right-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(180deg);
  }
}

@keyframes left-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes close-wrapper {
  to {
    clip: rect(auto, auto, auto, auto);
  }
}

.h-hive__header {
  margin: 50px 0 20px;
  text-align: center;
}

.h-hive__title {
  display: block;
  max-width: 800px;
  margin-top: 0;
  margin-right: auto;
  margin-bottom: 24px;
  margin-left: auto;
  font-size: 28px;
  letter-spacing: .05em;
  line-height: 1;
  text-align: center;
  text-transform: uppercase;
}

@media (min-width: 768px) {
  .h-hive__title {
    font-size: 35px;
  }
}

@media (min-width: 992px) {
  .h-hive__title {
    font-size: 44px;
  }
}

@media (min-width: 1200px) {
  .h-hive__title {
    font-size: 55px;
  }
}

.h-hive__title--purple {
  color: #360c6e;
}

.h-hive__description {
  display: block;
  color: #505050;
  font-size: 16px;
  line-height: 1.2;
}

@media (min-width: 768px) {
  .h-hive__description {
    font-size: 18px;
  }
}

@media (min-width: 992px) {
  .h-hive__description {
    font-size: 22px;
  }
}

@media (min-width: 1200px) {
  .h-hive__description {
    font-size: 26px;
  }
}

.h-jobs {
  background-image: url("../images/bg-cover-home-jobs.jpg");
  background-position: 50% 50%;
  background-size: cover;
}

.h-jobs__container {
  display: block;
  max-width: 813px;
  margin: 0 auto;
  padding-top: 55px;
  padding-bottom: 148px;
  color: #fff;
}

.h-jobs__title {
  display: block;
  margin-top: 0;
  margin-bottom: 0.6323529411764706em;
  font-size: 32px;
  font-weight: 300;
  letter-spacing: .05em;
  line-height: 1.1176470588235294;
  text-align: center;
  text-transform: uppercase;
}

@media (min-width: 768px) {
  .h-jobs__title {
    font-size: 38px;
  }
}

@media (min-width: 992px) {
  .h-jobs__title {
    font-size: 45px;
  }
}

@media (min-width: 1200px) {
  .h-jobs__title {
    font-size: 55px;
  }
}

.h-jobs__title b {
  color: #39e2b4;
  font-weight: 700;
}

.h-jobs__description {
  display: block;
  margin-bottom: 24px;
  font-size: 18px;
  letter-spacing: .05em;
  line-height: 1.24;
  text-align: center;
}

@media (min-width: 768px) {
  .h-jobs__description {
    font-size: 20px;
  }
}

@media (min-width: 992px) {
  .h-jobs__description {
    font-size: 24px;
  }
}

.h-jobs__grid {
  display: grid;
  grid-template-columns: repeat(1, minmax(260px, auto) [col-start]);
  grid-column-gap: 16px;
  grid-row-gap: 7px;
}

@media (min-width: 768px) and (max-width: 992px) {
  .h-jobs__grid {
    grid-template-columns: repeat(2, minmax(260px, auto) [col-start]);
  }
}

@media (min-width: 992px) {
  .h-jobs__grid {
    grid-template-columns: repeat(3, minmax(auto, 260px) [col-start]);
  }
}

@media (max-width: 767px) {
  .h-jobs__item:nth-child(2n) .h-jobs__button {
    background: rgba(57, 226, 180, 0.2);
    color: #39e2b4;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .h-jobs__item:nth-child(4n+3) .h-jobs__button, .h-jobs__item:nth-child(4n+4) .h-jobs__button {
    background: rgba(57, 226, 180, 0.2);
    color: #39e2b4;
  }
}

@media (min-width: 992px) {
  .h-jobs__item:nth-child(6n+4) .h-jobs__button, .h-jobs__item:nth-child(6n+5) .h-jobs__button, .h-jobs__item:nth-child(6n+6) .h-jobs__button {
    background: rgba(57, 226, 180, 0.2);
    color: #39e2b4;
  }
}

.h-jobs__button {
  display: flex;
  position: relative;
  align-items: center;
  min-height: 70px;
  padding: 0 15px;
  border: 1px solid #fff;
  background: rgba(54, 12, 110, 0.2);
  color: #360c6e;
  overflow: hidden;
  z-index: 2;
}

.h-jobs__button:before {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: translateX(calc(-100% - 1px));
  transition: transform .5s;
  background: currentColor;
  content: "";
  z-index: -1;
}

.h-jobs__button:hover {
  color: #360c6e;
  text-decoration: none;
}

.h-jobs__button:hover:before {
  transform: translateX(0);
}

.h-jobs__button:hover .h-jobs__button-icon {
  left: calc(100% - 33px);
  color: #fff;
}

.h-jobs__button:hover .h-jobs__button-text {
  transform: translateX(-63px);
  color: #fff;
}

.h-jobs__button-icon {
  display: block;
  position: relative;
  left: 0;
  flex: 0 0 33px;
  width: 33px;
  height: 33px;
  margin-right: 30px;
  transition: left .4s;
  color: #fff;
}

.h-jobs__button-text {
  flex: 0 0 calc(100% - 63px);
  transition: transform .6s;
  color: #fff;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: .05em;
  line-height: 1.2;
  text-transform: uppercase;
}

.h-jobs__footer {
  align-items: center;
  justify-content: space-between;
  margin-top: 23px;
  color: #fff;
  font-size: 16px;
  letter-spacing: .1em;
}

@media (min-width: 768px) {
  .h-jobs__footer {
    display: flex;
    font-size: 18px;
    text-align: center;
  }
}

@media (min-width: 992px) {
  .h-jobs__footer {
    font-size: 20px;
  }
}

.h-jobs__footer-icon {
  display: block;
  width: 30px;
  height: 30px;
  margin-right: 14px;
  transition: color .4s;
  color: #39e2b4;
}

.h-jobs__footer-button {
  display: flex;
  flex-basis: 250px;
  justify-content: center;
  padding: 16px 43px;
  transition: color .4s, border-color .4s, background .4s;
  border: 1px solid #39e2b4;
  border-radius: 32px;
  color: #fff;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: .05em;
  text-transform: uppercase;
  white-space: nowrap;
}

@media (max-width: 767px) {
  .h-jobs__footer-button {
    margin-top: 1em;
  }
}

.h-jobs__footer-button:hover {
  border-color: #39e2b4;
  background: #39e2b4;
  color: #3a3a3a;
  text-decoration: none;
}

.h-jobs__footer-button:hover .h-jobs__footer-icon {
  color: #3a3a3a;
}

.og-header__container {
  width: 100%;
  max-width: 970px;
  margin: 0 auto;
  padding-top: 42px;
  padding-bottom: 64px;
  text-align: center;
}

@media (min-width: 992px) {
  .og-header__container {
    padding-top: 0;
    padding-bottom: 42px;
  }
}

.og-header__title {
  display: block;
  max-width: 100%;
  color: #000;
  font-size: 32px;
  font-weight: 300;
  letter-spacing: .05em;
  line-height: 1.018;
  text-align: center;
  text-transform: uppercase;
}

@media (min-width: 768px) and (max-width: 992px) {
  .og-header__title {
    font-size: 45px;
  }
}

@media (min-width: 992px) {
  .og-header__title {
    color: #fff;
    font-size: 55px;
  }
}

@media (max-width: 767px) {
  .og-header__title {
    margin: 0 0 1em;
  }
}

.og-header__title strong {
  font-weight: bold;
}

.og-header__description {
  display: block;
  flex: 0 1 650px;
  max-width: 100%;
  color: #949494;
  font-size: 18px;
  letter-spacing: .05em;
  line-height: 1.2;
}

@media (min-width: 992px) {
  .og-header__description {
    font-size: 21px;
  }
}

.og-header__description p {
  margin: 0;
}

.og-header__description p + p {
  margin-top: 1em;
}

.og-carousel {
  position: relative;
  z-index: 1;
}

@media (min-width: 992px) {
  .og-carousel {
    padding-top: 70px;
    padding-bottom: 160px;
  }
}

.og-carousel__background {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  overflow: hidden;
  visibility: hidden;
  z-index: -1;
}

@media (min-width: 992px) {
  .og-carousel__background {
    opacity: 1;
    visibility: visible;
  }
}

.og-carousel__background:after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  content: "";
}

.og-carousel__background .slick-list,
.og-carousel__background .slick-track {
  height: 100%;
}

.og-carousel__background-item {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-position: 50% 50%;
  background-size: cover;
}

.og-carousel__container {
  position: relative;
  box-shadow: 8px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 2;
}

.og-carousel__container .slick-dots {
  display: flex;
  position: absolute;
  right: 0;
  bottom: 0;
  width: 100%;
  max-width: 580px;
  padding: 30px 0 30px 58px;
  list-style: none;
}

.og-carousel__container .slick-dots li {
  display: block;
}

.og-carousel__container .slick-dots li + li {
  padding-left: 9px;
}

.og-carousel__container .slick-dots button {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  display: block;
  width: 13px;
  height: 13px;
  transition: opacity .5s, transform .5s;
  border: 0;
  border-radius: 50%;
  background: #fff;
  text-indent: -999px;
  cursor: pointer;
  opacity: .38;
  overflow: hidden;
}

.og-carousel__container .slick-dots .slick-active > button {
  transform: scale(1.2);
  opacity: 1;
}

.og-carousel__container .slick-arrow {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  position: absolute;
  top: 50%;
  padding: 0;
  border: 0;
  background: transparent;
  z-index: 3;
}

.og-carousel__container .slick-arrow svg {
  width: 44px;
  height: 44px;
}

.og-carousel__container .slick-arrow.slick-next {
  right: 0;
  transform: translateX(50%);
}

.og-carousel__container .slick-arrow.slick-prev {
  left: 0;
  transform: translateX(-50%);
}

.og-carousel__billboard {
  display: flex;
  position: relative;
  margin-right: -15px;
  margin-left: -15px;
  background-position: 0 0;
  background-size: cover;
}

@media (min-width: 992px) {
  .og-carousel__billboard {
    width: 100%;
    height: 612px;
    max-height: 100vh;
    margin-right: 0;
    margin-left: 0;
    background-position: 50% 50%;
  }
}

.og-carousel__badge {
  position: absolute;
  top: -55px;
  right: 15px;
}

@media (min-width: 992px) {
  .og-carousel__badge {
    top: 38px;
    right: auto;
    left: 27px;
    transform: translateY(50px);
    transition: opacity .5s .9s, transform .5s .9s;
    opacity: 0;
  }
}

.slick-active .og-carousel__badge {
  transform: translateY(0);
  opacity: 1;
}

.og-carousel__content {
  display: block;
  margin-top: 200px;
  padding: 30px 15px 80px;
  transition: opacity .75s .5s;
  background: rgba(29, 29, 29, 0.9);
}

@media (min-width: 992px) {
  .og-carousel__content {
    flex: 0 1 580px;
    margin-top: 0;
    margin-left: auto;
    padding: 94px 78px 74px 58px;
    opacity: 0;
  }
}

.slick-active .og-carousel__content {
  opacity: 1;
}

.og-carousel__content--blue {
  background: rgba(10, 27, 66, 0.9);
}

.og-carousel__content--lightblue {
  background: rgba(80, 128, 173, 0.9);
}

.og-carousel__content--red {
  background: rgba(206, 31, 31, 0.9);
}

.og-carousel__content--darkpurple {
  background: rgba(35, 34, 56, 0.9);
}

.og-carousel__content--gold {
  background: rgba(68, 39, 6, 0.9);
}

.og-carousel__header {
  display: block;
  margin-bottom: 45px;
  color: #fff;
}

@media (min-width: 768px) {
  .og-carousel__header {
    margin-bottom: 45px;
  }
}

@media (min-width: 992px) {
  .og-carousel__header {
    transform: translateY(50px);
    transition: opacity .5s .7s, transform .5s .7s;
    opacity: 0;
  }
}

.slick-active .og-carousel__header {
  transform: translateY(0);
  opacity: 1;
}

.og-carousel__header--turquoise {
  color: #39e2b4;
}

.og-carousel__title {
  display: block;
  margin: 0;
  font-size: 32px;
  font-weight: bold;
  line-height: 1;
  text-transform: uppercase;
}

@media (min-width: 768px) and (max-width: 992px) {
  .og-carousel__title {
    font-size: 36px;
  }
}

@media (min-width: 992px) {
  .og-carousel__title {
    font-size: 45px;
  }
}

.og-carousel__subtitle {
  display: block;
  margin: 0;
  font-size: 20px;
  line-height: 1;
  text-transform: uppercase;
}

@media (min-width: 768px) and (max-width: 992px) {
  .og-carousel__subtitle {
    font-size: 28px;
  }
}

@media (min-width: 992px) {
  .og-carousel__subtitle {
    font-size: 38px;
  }
}

.og-carousel__description {
  display: block;
  color: #fff;
  font-size: 16px;
  letter-spacing: .05em;
  line-height: 1.3;
}

@media (min-width: 768px) {
  .og-carousel__description {
    font-size: 18px;
  }
}

@media (min-width: 992px) {
  .og-carousel__description {
    transform: translateY(50px);
    transition: opacity .5s .8s, transform .5s .8s;
    opacity: 0;
  }
}

.slick-active .og-carousel__description {
  transform: translateY(0);
  opacity: 1;
}

.og-carousel__description > * {
  margin: 0;
}

.og-carousel__description > * + * {
  margin-top: 1em;
}

.ab-header {
  background: #000;
}

.ab-header__container {
  display: flex;
  align-items: center;
  width: 100%;
  margin: 0 auto;
  padding-top: 42px;
  padding-bottom: 42px;
}

@media (max-width: 767px) {
  .ab-header__container {
    flex-wrap: wrap;
    align-content: center;
    padding-bottom: 79px;
  }
}

.ab-header__title {
  display: block;
  flex: 1 1 550px;
  max-width: 100%;
  color: #fff;
  font-size: 32px;
  font-weight: 300;
  letter-spacing: .05em;
  line-height: 1.018;
  text-align: left;
  text-transform: uppercase;
}

@media (min-width: 768px) and (max-width: 992px) {
  .ab-header__title {
    font-size: 42px;
  }
}

@media (min-width: 992px) {
  .ab-header__title {
    font-size: 52px;
  }
}

@media (max-width: 767px) {
  .ab-header__title {
    margin: 0 0 1em;
  }
}

.ab-header__title strong {
  font-weight: bold;
}

.ab-header__description {
  display: block;
  flex: 0 1 550px;
  max-width: 100%;
  color: #fff;
  font-size: 18px;
  letter-spacing: .05em;
  line-height: 1.2;
}

@media (min-width: 992px) {
  .ab-header__description {
    font-size: 20px;
  }
}

.ab-header__description p {
  margin: 0;
}

.ab-header__description p + p {
  margin-top: 1em;
}

.ab-slidepanel {
  padding-top: 20px;
  padding-bottom: 40px;
}

@media (min-width: 992px) {
  .ab-slidepanel {
    padding-top: 43px;
    padding-bottom: 120px;
  }
}

.ab-slidepanel__wrapper {
  display: flex;
  margin-right: -15px;
  margin-left: -15px;
}

.ab-slidepanel__container {
  position: relative;
  flex: 0 0 50%;
  max-width: 50%;
}

@media (min-width: 992px) {
  .ab-slidepanel__container {
    padding-right: 15px;
    padding-left: 15px;
  }
}

.ab-slidepanel__container--left {
  padding-top: 120vw;
  padding-top: 120vmin;
}

@media (min-width: 768px) and (max-width: 992px) {
  .ab-slidepanel__container--left {
    padding-top: 62.5vw;
    padding-top: 62.5vmin;
  }
}

@media (min-width: 992px) {
  .ab-slidepanel__container--left {
    padding-top: 465px;
  }
}

.ab-slidepanel__container--right {
  padding-top: 120vw;
  padding-top: 120vmin;
}

@media (min-width: 768px) and (max-width: 992px) {
  .ab-slidepanel__container--right {
    padding-top: 62.5vw;
    padding-top: 62.5vmin;
  }
}

@media (min-width: 992px) {
  .ab-slidepanel__container--right {
    padding-top: 0;
  }
}

.ab-slidepanel__slide, .ab-slidepanel__carousel, .ab-slidepanel__panel, .ab-slidepanel__perspective {
  width: 100%;
  max-width: 558px;
  height: 54.659498207885306vw;
  max-height: 610px;
}

.ab-slidepanel__perspective {
  perspective: 218.63799283154123vw;
}

@media only screen and (min-width: 1116px) {
  .ab-slidepanel__perspective {
    perspective: 2440px;
  }
}

.ab-slidepanel__panel {
  background-position: 50% 50%;
  background-size: cover;
}

.ab-slidepanel__carousel {
  position: relative;
  width: inherit;
  max-width: inherit;
  height: inherit;
  max-height: inherit;
}

.ab-slidepanel__buttons {
  display: flex;
  position: absolute;
  top: 120vw;
  top: 120vmin;
  justify-content: center;
  width: 100%;
  padding: 0 10px;
  transform: translateY(-50%);
  z-index: 2;
}

@media (min-width: 768px) and (max-width: 992px) {
  .ab-slidepanel__buttons {
    top: 62.5vw;
    top: 62.5vmin;
    padding: 0 20px;
  }
}

@media (min-width: 992px) {
  .ab-slidepanel__buttons {
    position: relative;
    top: -25%;
    justify-content: space-between;
    width: auto;
    margin: 0 -27.5px;
    padding: 0;
    transform: translateY(-100%);
  }
}

.ab-slidepanel__button {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  flex-shrink: 0;
  width: 55px;
  height: 55px;
  margin-right: 5px;
  margin-left: 5px;
  border: 0;
  border-radius: 50%;
  background: #39e2b4;
  overflow: hidden;
}

.ab-slidepanel__content {
  display: flex;
  position: absolute;
  bottom: 100%;
  flex-direction: column;
  justify-content: center;
  width: calc(100vw - 30px);
  height: 120vw;
  height: 120vmin;
  padding: 15px;
  transition: opacity .2s;
  opacity: 0;
}

@media (min-width: 768px) and (max-width: 992px) {
  .ab-slidepanel__content {
    height: 62.5vw;
    height: 62.5vmin;
  }
}

@media (min-width: 992px) {
  .ab-slidepanel__content {
    width: 100%;
    height: 465px;
    padding-right: 0;
    padding-left: 0;
  }
}

.ab-carousel--forward .ab-carousel__item--bottom .ab-slidepanel__content,
.ab-carousel--backward .ab-carousel__item--top .ab-slidepanel__content,
.ab-carousel__item--active .ab-slidepanel__content {
  opacity: 1;
}

.ab-slidepanel__title {
  display: block;
  margin-bottom: 0.727273em;
  color: #505050;
  font-size: calc(2rem + ((1vw - 3.2px) * 2.6136));
  font-weight: bold;
  letter-spacing: .07em;
  line-height: 1.018;
  text-transform: uppercase;
}

@media (min-width: 1200px) {
  .ab-slidepanel__title {
    font-size: 55px;
  }
}

.ab-slidepanel__description {
  display: block;
  color: #949494;
  font-size: calc(1.5rem + ((1vw - 3.2px) * 0.3409));
  letter-spacing: .07em;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .ab-slidepanel__description {
    font-size: 21px;
  }
}

.ab-carousel__container {
  transform: translateZ(-27.3297491039vw) rotateX(0deg);
  transform-style: preserve-3d;
  transition: transform 1s ease-out;
}

@media only screen and (min-width: 1116px) {
  .ab-carousel__container {
    transform: translateZ(-305px) rotateX(0deg);
  }
}

.ab-carousel__container.ab-carousel__container--left.ab-carousel--forward, .ab-carousel__container.ab-carousel__container--right.ab-carousel--backward {
  transform: translateZ(-27.3297491039vw) rotateX(90deg);
}

@media only screen and (min-width: 1116px) {
  .ab-carousel__container.ab-carousel__container--left.ab-carousel--forward, .ab-carousel__container.ab-carousel__container--right.ab-carousel--backward {
    transform: translateZ(-305px) rotateX(90deg);
  }
}

.ab-carousel__container.ab-carousel__container--right.ab-carousel--forward, .ab-carousel__container.ab-carousel__container--left.ab-carousel--backward {
  transform: translateZ(-27.3297491039vw) rotateX(-90deg);
}

@media only screen and (min-width: 1116px) {
  .ab-carousel__container.ab-carousel__container--right.ab-carousel--forward, .ab-carousel__container.ab-carousel__container--left.ab-carousel--backward {
    transform: translateZ(-305px) rotateX(-90deg);
  }
}

.ab-carousel__container.disable-transitions {
  transition: none !important;
}

.ab-carousel__item {
  display: block;
  position: absolute;
  visibility: hidden;
}

.ab-carousel__item--top, .ab-carousel__item--active, .ab-carousel__item--bottom {
  visibility: visible;
}

.ab-carousel__item--active {
  transform: translateZ(27.3297491039vw);
}

@media only screen and (min-width: 1116px) {
  .ab-carousel__item--active {
    transform: translateZ(305px);
  }
}

.ab-carousel__item--top {
  transform: translateY(-27.3297491039vw) rotateX(90deg);
}

@media only screen and (min-width: 1116px) {
  .ab-carousel__item--top {
    transform: translateY(-305px) rotateX(90deg);
  }
}

.ab-carousel__item--bottom {
  transform: translateY(27.3297491039vw) rotateX(270deg);
}

@media only screen and (min-width: 1116px) {
  .ab-carousel__item--bottom {
    transform: translateY(305px) rotateX(270deg);
  }
}
