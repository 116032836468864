.header {
  position: fixed;
  top: 0;
  width: 100%;
  background: #000;
  z-index: 1050;

  @include mq(sm) {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  &__mobile-nav {
    display: flex;
    position: absolute;
    top: 100%;
    right: 0;
    left: 0;
    flex-direction: column;
    align-items: center;
    height: calc(100vh - 74px);
    padding-top: 0;
    padding-bottom: 30px;
    transform: translateY(-100%);
    transition: opacity .3s .2s, transform .5s;
    background: #000;
    opacity: 0;
    z-index: -1;

    .is-nav--open & {
      transform: translateY(0);
      opacity: 1;
    }

    @include mq(sm) {
      position: static;
      flex-direction: row;
      flex-grow: 1;
      height: auto;
      padding: 0;
      transform: none;
      opacity: 1;
      z-index: 1;
    }
  }

  &__logo {
    display: block;
    width: 44px;
    height: 44px;
    transition: color .3s;
    color: #fff;

    @include mq(sm) {
      width: 66px;
      height: 66px;
    }

    &:hover {
      color: $turquoise;
    }
  }

  &__container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 15px;
    padding-bottom: 15px;
    background: #000;

    @include mq(sm) {
      justify-content: flex-start;
      padding: 0;
    }
  }

  &__nav {
    display: flex;
    flex-grow: 1;
    justify-content: center;
  }

  &__lang {
    display: flex;
    margin: 0;
    padding: 0;
    color: #fff;
    font-size: 14px;
    letter-spacing: .05em;
    line-height: 1.2;
    list-style: none;

    @include mq(xs) {
      transform: translateY(30px);
      transition: transform .3s .8s, opacity .2s .8s;
      opacity: 0;

      .is-nav--open & {
        transform: translateY(0);
        opacity: 1;
      }
    }

    &-item {

      &.is--active {
        color: $turquoise;
      }

      a {
        color: inherit;

        &:hover {
          color: inherit;
          // text-decoration: none;
        }
      }

      + #{&} {
        &:before {
          display: inline-block;
          padding: 0 1ch;
          content: "|";
        }
      }
    }
  }

  &__toggle {
    @include appearance(none);
    display: block;
    width: 44px;
    height: 44px;
    padding: .75em .75em;
    border: 0;
    background: transparent;

    @include mq(sm) {
      display: none;
    }

    .is-nav--open & {
      i {
        transform: rotate(-45deg);
        transform-origin: 50% 50%;

        &:first-child {
          transform: rotate(45deg) translate(3px, -3.75px);
          transform-origin: 0 0;
        }

        &:last-child {
          transform: rotate(45deg);
          transform-origin: 100% 100%;
        }
      }
    }

    &-icon {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-around;
      height: 100%;
      text-align: left;

      i {
        display: block;
        width: 100%;
        height: 3px;
        transition: transform .3s cubic-bezier(.25,.1, .5, 1.5);
        background: #fff;

        &:first-child {
          width: 57.142857142857146%;
        }

        &:last-child {
          width: 78.57142857142857%;
        }
      }
    }
  }
}
