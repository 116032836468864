.ab-carousel {

  &__container {
    transform: translateZ(-27.329749103942653vw) rotateX(0deg);
    transform-style: preserve-3d;
    transition: transform 1s ease-out;

    @media only screen and (min-width: 1116px) {
      transform: translateZ(-305px) rotateX(0deg);
    }

    &.ab-carousel__container--left.ab-carousel--forward,
    &.ab-carousel__container--right.ab-carousel--backward {
      transform: translateZ(-27.329749103942653vw) rotateX(90deg);

      @media only screen and (min-width: 1116px) {
        transform: translateZ(-305px) rotateX(90deg);
      }
    }

    &.ab-carousel__container--right.ab-carousel--forward,
    &.ab-carousel__container--left.ab-carousel--backward {
      transform: translateZ(-27.329749103942653vw) rotateX(-90deg);

      @media only screen and (min-width: 1116px) {
        transform: translateZ(-305px) rotateX(-90deg);
      }
    }

    &.disable-transitions {
      transition: none !important;
    }
  }

  &__item {
    display: block;
    position: absolute;
    visibility: hidden;

    &--top,
    &--active,
    &--bottom {
      visibility: visible;
    }

    &--active {
      transform: translateZ(27.329749103942653vw);

      @media only screen and (min-width: 1116px) {
        transform: translateZ(305px);
      }
    }

    &--top {
      transform: translateY(-27.329749103942653vw) rotateX(90deg);

      @media only screen and (min-width: 1116px) {
        transform: translateY(-305px) rotateX(90deg);
      }
    }

    &--bottom {
      transform: translateY(27.329749103942653vw) rotateX(270deg);

      @media only screen and (min-width: 1116px) {
        transform: translateY(305px) rotateX(270deg);
      }
    }
  }
}

