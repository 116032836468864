.nav {
  &__list {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0;
    padding: 0;
    list-style: none;

    @include mq(sm) {
      flex-direction: row;
    }
  }

  &__item {
    padding-right: 25px;
    padding-left: 25px;
    color: #fff;
    font-size: 20px;
    line-height: 1.2;
    text-transform: uppercase;

    &.is--active {
      color: $turquoise;
    }

    @include mq(xs) {
      transform: translateX(-30px);
      transition: transform .3s .6s, opacity .2s .6s;
      opacity: 0;

      &:first-child {
        transition: transform .3s .5s, opacity .2s .5s;
      }

      &:last-child {
        transition: transform .3s .7s, opacity .2s .7s;
      }

      .is-nav--open & {
        transform: translateX(0);
        opacity: 1;
      }
    }
  }

  &__button {
    display: block;
    position: relative;
    padding: 10px 0;
    transition: color .2s;
    color: inherit;

    &:after {
      display: block;
      position: absolute;
      top: 100%;
      width: 100%;
      height: 8px;
      transform: scaleX(.01);
      transition: transform .3s, opacity .01s;
      border-radius: 4px;
      background: currentColor;
      content: "";
      opacity: 0;
    }

    &:hover {
      color: $turquoise;
      text-decoration: none;

      &:after {
        transform: scaleX(1);
        opacity: 1;
      }
    }
  }
}
