html,
body {
  font-size: 10px;
}

body {
  font-family: Dosis, sans-serif;
}

body.is-nav--open {
  overflow: hidden;
}


.container-fluid {
  padding-right: 0;
  padding-left: 0;
  // overflow-x: hidden;
}

.wrapper {
  width: 100%;
  max-width: em(1176);
  margin: 0 auto;
  padding-right: rem(15);
  padding-left: rem(15);

  &--small {
    max-width: em(1024);
  }
}

.la-main {
  padding-top: 74px;

  @include mq(sm) {
    padding-top: 126px;
  }
}

.text {
  > * {
    margin: 0;

    + * {
      margin-top: 1em;
    }
  }
}
