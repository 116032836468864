.og-carousel {
  position: relative;
  // background: #000;
  z-index: 1;

  @include mq(md) {
    padding-top: 70px;
    padding-bottom: 160px;
  }

  &__background {
    position: absolute;
    top: 0; right: 0; bottom: 0; left: 0;
    opacity: 0;
    overflow: hidden;
    visibility: hidden;
    z-index: -1;

    @include mq(md) {
      opacity: 1;
      visibility: visible;
    }

    &:after {
      position: absolute;
      top: 0; right: 0; bottom: 0; left: 0;
      content: "";
    }

    .slick-list,
    .slick-track {
      height: 100%;
    }

    &-item {
      position: absolute;
      top: 0; left: 0;
      width: 100%;
      height: 100%;
      background-position: 50% 50%;
      background-size: cover;
    }
  }

  &__container {
    position: relative;
    box-shadow: 8px 8px 16px 0px rgba(0, 0, 0, .2);
    z-index: 2;

    .slick-dots {
      display: flex;
      position: absolute;
      right: 0;
      bottom: 0;
      width: 100%;
      max-width: 580px;
      padding: 30px 0 30px 58px;
      list-style: none;

      li {
        display: block;

        + li {
          padding-left: 9px;
        }
      }

      button {
        @include appearance(none);
        display: block;
        width: 13px;
        height: 13px;
        transition: opacity .5s, transform .5s;
        border: 0;
        border-radius: 50%;
        background: #fff;
        text-indent: -999px;
        cursor: pointer;
        opacity: .38;
        overflow: hidden;
      }

      .slick-active > button {
        transform: scale(1.2);
        opacity: 1;
      }
    }

    .slick-arrow {
      @include appearance(none);
      position: absolute;
      top: 50%;
      padding: 0;
      border: 0;
      background: transparent;
      z-index: 3;

      svg {
        width: 44px;
        height: 44px;
      }

      &.slick-next {
        right: 0;
        transform: translateX(50%);
      }

      &.slick-prev {
        left: 0;
        transform: translateX(-50%);
      }
    }
  }

  &__billboard {
    display: flex;
    position: relative;
    margin-right: -15px;
    margin-left: -15px;
    background-position: 0 0;
    background-size: cover;

    @include mq(md) {
      width: 100%;
      height: 612px;
      max-height: 100vh;
      margin-right: 0;
      margin-left: 0;
      background-position: 50% 50%;
    }
  }

  &__badge {
    position: absolute;
    top: -55px;
    right: 15px;
    // transform: scale(.8);

    @include mq(md) {
      top: 38px;
      right: auto;
      left: 27px;
      transform: translateY(50px);
      transition: opacity .5s .9s, transform .5s .9s;
      opacity: 0;
    }

    .slick-active & {
      transform: translateY(0);
      opacity: 1;
    }
  }

  &__content {
    display: block;
    margin-top: 200px;
    padding: 30px 15px 80px;
    transition: opacity .75s .5s;
    background: rgba(#1d1d1d, .9);

    @include mq(md) {
      flex: 0 1 580px;
      margin-top: 0;
      margin-left: auto;
      padding: 94px 78px 74px 58px;
      opacity: 0;
    }

    .slick-active & {
      opacity: 1;
    }

    &--blue {
      background: rgba(#0a1b42, .9);
    }

    &--lightblue {
      background: rgba(#5080ad, .9);
    }

    &--red {
      background: rgba(#ce1f1f, .9);
    }

    &--darkpurple {
      background: rgba(#232238, .9);
    }

    &--gold {
      background: rgba(#442706, .9);
    }
  }

  &__header {
    display: block;
    margin-bottom: 45px;
    color: #fff;

    @include mq(sm) {
       margin-bottom: 45px;
    }

    @include mq(md) {
      transform: translateY(50px);
      transition: opacity .5s .7s, transform .5s .7s;
      opacity: 0;
    }

    .slick-active & {
      transform: translateY(0);
      opacity: 1;
    }

    &--turquoise {
      color: $turquoise;
    }
  }

  &__title {
    display: block;
    margin: 0;
    font-size: 32px;
    font-weight: bold;
    line-height: 1;
    text-transform: uppercase;

    @include mq(only-sm) {
      font-size: 36px;
    }

    @include mq(md) {
      font-size: 45px;
    }

  }

  &__subtitle {
    display: block;
    margin: 0;
    font-size: 20px;
    line-height: 1;
    text-transform: uppercase;

    @include mq(only-sm) {
      font-size: 28px;
    }

    @include mq(md) {
      font-size: 38px;
    }
  }

  &__description {
    display: block;
    color: #fff;
    font-size: 16px;
    letter-spacing: .05em;
    line-height: 1.3;

    @include mq(sm) {
      font-size: 18px;
    }

    @include mq(md) {
      transform: translateY(50px);
      transition: opacity .5s .8s, transform .5s .8s;
      // font-size: 20px;
      opacity: 0;
    }

    .slick-active & {
      transform: translateY(0);
      opacity: 1;
    }

    > * {
      margin: 0;

      + * {
        margin-top: 1em;
      }
    }
  }
}
